import React from 'react';
import Coluna from "../../Assets/coluna.webp";
import Cranio from "../../Assets/cranio.webp";
import Dor from "../../Assets/dor.webp";
import './especialidades.css';

const Especialidades = () => {
  return (
    <section id='especialidades'>
      <div className='title'>
        <h1>HÁ QUANTO TEMPO VOCÊ BUSCA ALGUÉM QUE VERDADEIRAMENTE TE AJUDE?</h1>
      </div>
      <div className='especialidades-container'>
        <div className='item'>
        <div className='text-item' data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="2500">
          <h2>PRECISA CUIDAR DO CÉREBRO?</h2>
          <p>
            Recebo pessoas diariamente com doenças do cérebro, como tumores, hidrocefalias e aneurismas cerebrais. Minha experiência me permite uma visão mais humanizada e centrada na pessoa. Assim, o cuidado desses pacientes se inicia com uma avaliação cuidadosa dos sintomas e da sua história, dos exames de imagem e da doença à qual estamos de frente. Desta forma, é possível determinar o tratamento ideal para cada caso, com maior chance de sucesso, seja por meio de tratamento cirúrgico ou não cirúrgico.
          </p>
          <p>
            No Laboratório de Microcirurgia da Beneficência Portuguesa, em São Paulo, desenvolvi técnicas cirúrgicas menos invasivas, com uso de microscópio, para tratamento cirúrgico dessas doenças, quando devidamente indicado.
          </p>
          <p>
            Pacientes com Doença de Parkinson e outros transtornos do movimento também me buscam para avaliação quanto à possibilidade de cirurgia, dada minha experiência tendo atuado no grupo de cirurgias para transtornos do movimento do Hospital Pedro Ernesto da UERJ.
          </p>
          </div>
          </div>

        <div className='item'>
          <img src={Cranio} alt="Tratamento de crânio" />
        </div>
        <div className='item'>
          <img src={Coluna} alt="Tratamento de coluna" />
        </div>
        <div className='item'>
          <div className='text-item' data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="2500">
            <h2>COMO VAI A SUA COLUNA?</h2>
            <p>
              A dor na coluna é um dos principais motivos pelo qual buscam atendimento comigo. Tenho grande experiência cirúrgica e não-cirúrgica no tratamento de pacientes com dores na coluna devido à doença degenerativa, como em hérnias de disco, estenoses de canal e os famosos “bicos de papagaio”.
            </p>
            <p>
              Meu trabalho envolve diversas modalidades de tratamento, como o medicamentoso, a reabilitação, procedimentos percutâneos (como a infiltração) e, se indicados, os cirúrgicos, a depender do que será mais resolutivo de forma direcionada especificamente ao seu caso.
            </p>
            <p>
              Minha experiência cirúrgica envolve cirurgias de diversos níveis de complexidade de coluna e inclui procedimentos minimamente invasivos e endoscópicos, tendo operado centenas de pacientes com doença degenerativa da coluna no serviço de cirurgia da coluna do Hospital Pedro Ernesto da UERJ.
            </p>
          </div>
        </div>

        <div className='item'>
          <div className='text-item' data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="2500">
            <h2>SEU MAIOR PROBLEMA É A DOR?</h2>
            <p>
              Tratar sua dor crônica com eficácia é o que irá te devolver a qualidade de vida.
            </p>
            <p>
              A dor crônica, além da coluna, também pode ser localizada em outras regiões do corpo, sendo igualmente causa de sofrimento inimaginável. Alguns exemplos incluem as dores craniofaciais, como no caso da Neuralgia do trigêmeo e das dores neuropáticas e miofasciais, tendo atuado no ambulatório de Dores Craniofaciais e Neuralgia do Trigêmeo no Hospital Pedro Ernesto da UERJ.
            </p>
            <p>
              Ofereço tratamento por várias modalidades para pessoas com dores crônicas, incluindo medicamentoso, reabilitação, intervencionista minimamente invasivo e cirúrgico, levando em consideração a melhor resposta possível para cada paciente.
            </p>
          </div>
        </div>

        <div className='item'>
          <img src={Dor} alt="Tratamento da dor" />
        </div>
      </div>
    </section>
  );
};

export default Especialidades;
