import React from 'react';
import MinhaImagem from "../../Assets/sobre-magno.jpg";
import './apresentacao.css';

const ImageWithText = () => {
    return (
        <section className="apresentacao" id="apresentacao">
            <div className='retrato'>
                <img src={MinhaImagem} alt="Retrato do Doutor" className="imagem" aria-label="Foto do Dr. Magno Rosa, neurocirurgião" />
            </div>
            <div className='descricao' data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="2500">
                <h1 id="nome-medico">DR. MAGNO ROSA</h1>
                <h2>NEUROCIRURGIÃO</h2>
                <h3>CRM: 52-113331-4 | RQE: 50.912</h3>
                {/* <p>Cirurgião de referência, com ampla experiência no tratamento de doenças do cérebro, da coluna, dos nervos e da dor</p> */}
                <p id="descricao-medico">
                    Minha formação como Médico, Mestre em Medicina e Neurocirurgião se deu na Universidade do Estado do Rio de Janeiro (UERJ) e no Hospital Pedro Ernesto (HUPE). 
                    Nesses mais de 10 anos ajudando a cuidar das pessoas, mantive uma visão humanizada da medicina, avaliando o indivíduo como um todo para oferecer uma medicina centrada na pessoa e não na doença. 
                    Acredito que essa visão e uma excelente relação médico-paciente são determinantes de um bom resultado no tratamento das pessoas.</p>
                <p>
                    Minha experiência no tratamento de pessoas com problemas de coluna, dor crônica e no cérebro inclui mais de 1000 procedimentos cirúrgicos, inclusive minimamente invasivos, além de outras formas de tratamento, como o medicamentoso, a reabilitação e procedimentos percutâneos. A escolha por cada modalidade é realizada com base não apenas na patologia e nos exames, mas em uma avaliação profunda do exame físico detalhado, dos sintomas e de toda a história que trouxe aquela pessoa a buscar atendimento comigo.
                </p>
            </div>
        </section>
    );
}

export default ImageWithText;
    